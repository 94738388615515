#aboutContainer {
  border-right: 4px solid var(--color);
  border-left: 4px solid var(--color);
}

.columnRubrik {
  margin: 20px;
  font-size: 18px;
  font-weight: 800;
  color: var(--accent);
  text-transform: uppercase;
  /* text-align: start; */
}

/* ==== About Text Column ==== */
#aboutTextCol {
  border-right: 5px solid var(--color);
  border-bottom: 5px solid var(--color);
  border-top: none;
  position: relative;
}

.about {
  text-align: start;
  font-size: 12px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.05rem;
  padding: 0 15px 10px 15px;
}

.socialMediaCol {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

#aboutSocialTop {
  border-top: 5px solid var(--color);
  border-bottom: 4px solid var(--color);
}

#aboutInstagram,
#aboutLinkedin,
#aboutCodesandbox,
#aboutGithub {
  color: var(--color);
  font-size: 50px;
}

#aboutSocialBottom {
  border-bottom: none;
}

.aboutSocialMediaColumn {
  padding-bottom: 10px;
  padding-top: 10px;
  border: none;
}

.socialCol {
  border-right: 5px solid var(--color);
}

.socialMediaCol {
  border: none;
}

/* ==== Profile Picture Column ==== */
#profilePicsCol {
  padding: 0;
  border-right: 5px solid var(--color);
  border-bottom: 5px solid var(--color);
  border-top: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profilCatti {
  padding: 5px;
  max-height: 800px;
  height: 100%;
}

/*  ==== Facts Column ====  */

/*  Facts Column  */
#factsCol {
  padding: 0;
  border-right: 5px solid var(--color);
  border-top: none;
  border-bottom: 5px solid var(--color);
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.factsContainer {
  /* padding: 0; */
  border: none;
}

#factsCol .row {
  border-top: none;
  border-bottom: 5px solid var(--color);
  border-left: none;
  border-right: none;
}

#factsCol .col {
  border: none;
}

#factsCol ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.factsRow {
  padding: 15px 0;
}

#factsCol ul li {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#factsTitle ul {
  margin-top: 20px;
  margin-bottom: 40px;
}

#factsAnswer ul {
  color: var(--accent);
  padding: 0;
  margin-top: 20px;
  margin-bottom: 40px;
}

/* Facts Theme Btn  */
#themeBtnAbout {
  margin: 1rem 0;
}

#ThemeBtnIcon {
  font-size: 50px;
}

/* Facts Skills */

#factsSkills .row {
  border: none;
  margin: 1.5rem 0;
}

#factsSkills #skillLineTwo {
  padding: 0;
}

#factsSkills ul li {
  text-transform: none;
  font-size: 11px;
  /* text-align: center; */
}

/* ==== Contact Column ==== */
#contactCol {
  padding: 0;
  position: relative;
  border-top: none;
}

#contactRubrik {
  margin-bottom: 1em;
}
.contact-form,
.divInput {
  border: none;
}

input,
textarea {
  border-left: none;
  border-right: none;
  background: var(--background-color);
  color: var(--color);
  width: 100%;
  border-top: 2px solid var(--color);
  border-bottom: 2px solid var(--color);
  padding: 17px 5px;
  font-size: 14px;
  font-weight: 600;
}

#fullname {
  border-top: 4px solid var(--color);
}

#message {
  border-bottom: none;
}

input::placeholder,
textarea::placeholder {
  color: var(--color);
  background: var(--background-color);
  border-radius: none;
}

input:focus,
textarea:focus {
  outline: none;
}

.sendBtnDiv {
  border-bottom: 4px solid var(--color);
  border-top: 4px solid var(--color);
  border-left: none;
  border-right: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.sendBtn {
  background: var(--background-color);
  border: none;
  font-weight: 800;
  color: var(--color);
  padding: 15px 0;
  transition: all 0.5s ease;
  width: 100%;
  text-transform: uppercase;
}

.sendBtn:hover {
  background: var(--accent);
}

/* === TOASTIFY === */
.Toastify,
.Toastify__toast-container,
.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-body div,
.Toastify__toast-icon,
.Toastify__progress-bar {
  border: none !important;
}

.Toastify__toast--success {
  background-color: var(--accent) !important;
  color: var(--color) !important;
}

.Toastify__close-button {
  color: var(--color) !important;
}

/* ==== HOTICE ==== */

.hoticeCol {
  border: none;
}

.hotice3D {
  max-width: 600px;
  width: 100%;
}

#hoticeTextCol {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.hoticeTextLog {
  margin: 4rem 0 2rem 0;
  max-width: 800px;
  width: 100%;
  color: var(--color);
}

.hoticeTextdiv {
  border: none;
}

.hoticeText {
  text-align: start;
  line-height: 30px;
  margin: 2rem 6rem 6rem 6rem;
  font-weight: 600;
}

.visitHoticeBtn {
  background: none;
  border: none;
  font-weight: 600;
  position: absolute;
  right: 200px;
  bottom: 120px;
}

.visitHoticeLink {
  text-decoration: none;
  color: var(--color);
  font-size: 14px;
  /* transition: 0.3s ease; */
}

.visitHoticeLink:hover {
  font-size: 16px;
  color: var(--accent);
}

.HoticeSocial {
  border: none;
}

h6 {
  font-size: 14px;
  position: absolute;
  right: 200px;
  bottom: 120px;
}

#hoticeInstagram {
  font-size: 30px;
  border: none;
  color: var(--color);
  position: absolute;
  right: 200px;
  bottom: 80px;
}

/*______Responsive xl______*/
@media (max-width: 2180px) {
  /*      About Column       */
  .about {
    line-height: 25px;
    letter-spacing: 0;
  }

  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 735px;
    height: 100%;
  }

  /*      Contact Column       */
  input,
  textarea {
    font-size: 12px;
  }
}

/*______Responsive______*/
@media (max-width: 1980px) {
  /*      About Column       */
  #aboutInstagram,
  #aboutLinkedin,
  #aboutCodesandbox,
  #aboutGithub {
    font-size: 40px;
  }

  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 700px;
    height: 100%;
  }
}

/*______Responsive______*/
@media (max-width: 1900px) {
  .columnRubrik {
    font-size: 14px;
  }
  /*      About Column       */
  .about {
    font-size: 11px;
    line-height: 22px;
  }

  #aboutInstagram,
  #aboutLinkedin,
  #aboutCodesandbox,
  #aboutGithub {
    font-size: 35px;
  }

  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 700px;
    height: 100%;
  }

  /*      Facts Column       */
  #factsCol {
    font-size: 9px;
  }

  .factsRow {
    padding: 10px 0;
  }

  #factsSkills ul li {
    font-size: 10px;
  }
}

/*Responsive*/
@media (max-width: 1915px) {
  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 600px;
    height: 100%;
  }
}

/*Responsive*/
@media (max-width: 1650px) {
  .columnRubrik {
    font-size: 16px;
  }

  /*      About Column       */
  .about {
    font-size: 10px;
    line-height: 20px;
  }

  #aboutInstagram,
  #aboutLinkedin,
  #aboutCodesandbox,
  #aboutGithub {
    font-size: 30px;
  }

  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 500px;
    height: 100%;
  }

  /*      Facts Column       */
  #factsCol {
    font-size: 9px;
  }

  #factsTitle {
    padding: 0;
  }

  #factsTitle ul {
    padding: 0 12px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #factsAnswer ul {
    padding: 0 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  #ThemeBtnIcon {
    font-size: 40px;
  }

  #factsAnswer {
    padding: 0;
  }

  .skillColOne {
    padding: 0;
  }

  #skillLineOne {
    padding: 0;
  }

  /*      Contact Column       */
  input,
  textarea {
    padding: 15px 5px;
    font-size: 10px;
  }

  .sendBtn {
    font-size: 12px;
  }

  /*      HOTICE       */
  .hoticeText {
    line-height: 25px;
    margin: 1rem 1rem 5rem 1rem;
    font-weight: 600;
    font-size: 14px;
  }

  .visitHoticeBtn,
  h6 {
    font-size: 13px;
    font-weight: 600;
    right: 10px;
    bottom: 12px;
  }

  .visitHoticeLink:hover {
    font-size: 14px;
  }

  #hoticeInstagram {
    font-size: 30px;
    left: 30px;
    bottom: 12px;
  }
}

/*______Responsive______*/
@media (max-width: 1370px) {
  .columnRubrik {
    font-size: 12px;
  }
  /*      About Column       */
  .about {
    font-size: 9px;
    font-weight: 400;
  }

  #aboutInstagram,
  #aboutLinkedin,
  #aboutCodesandbox,
  #aboutGithub {
    font-size: 25px;
  }

  .aboutSocialMediaColumn {
    padding-bottom: 5px;
    padding-top: 5px;
  }

  /*      Profile Picture Column       */
  .profilCatti {
    max-height: 400px;
    height: 100%;
  }
}

/*Responsive for tablet and mobile*/
@media (max-width: 1199px) {
  .columnRubrik {
    margin: 20px;
    font-size: 16px;
    font-weight: 800;
  }

  #aboutContainer {
    border-right: none;
    border-left: 3px solid var(--color);
  }

  /*      About Column      */
  #aboutTextCol {
    height: 100%;
    border-right: 4px solid var(--color);
  }

  .about {
    font-size: 10px;
    line-height: 25px;
  }

  .socialMediaCol {
    display: none;
  }

  /*      Profile Picture Column       */
  #profilePicsCol {
    height: 100%;
    border-right: 4px solid var(--color);
  }

  .profilCatti {
    max-height: 600px;
    height: 100%;
  }

  /*      Facts Column       */
  .factsContainer {
    max-width: 1199px;
    width: 100%;
  }

  #factsCol {
    height: 100%;
    font-size: 9px;
    border-right: 4px solid var(--color);
    border-bottom: 5px solid var(--color);
  }

  .themeBtnRow {
    display: none;
  }

  #factsTitle ul {
    text-align: right;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  #factsAnswer ul {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .ThemeModeCol {
    display: none;
  }

  #factsSkills {
    padding-bottom: 20px;
    font-size: 9px;
  }

  .skillColOne {
    padding: 0;
  }

  #skillLineOne {
    align-items: end;
  }

  .skillColTwo {
    padding-left: 20px;
    padding-right: 0;
  }

  #factsSkills .row {
    margin: 0;
  }

  #factsSkills ul li {
    font-size: 9px;
  }

  /*      Contact Column       */
  #contactCol {
    display: none;
  }

  /*      HOTICE       */
  .hoticeCol {
    border-right: 4px solid var(--color);
    border-left: 1px solid var(--color);
  }

  .hoticeTextLog {
    margin: 1em 0 2rem 0;
  }

  .hoticeText {
    margin: 0 2rem 6rem 2rem;
    font-size: 12px;
  }

  .visitHoticeBtn {
    right: 50px;
    bottom: 25px;
  }

  .visitHoticeLink {
    font-size: 10px;
  }

  #hoticeInstagram {
    margin: 0 10px;
    left: 50px;
    bottom: 30px;
    font-size: 25px;
  }

  h6 {
    font-size: 10px;
  }
}

/* <= 280 */
@media (max-width: 300px) {
  /*      Profile Picture Column       */

  .profilCatti {
    max-height: 400px;
    height: 100%;
  }
}
