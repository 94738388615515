:root {
  --gap: 2rem;
}

.marquee {
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
  padding-top: 1rem;
  padding-bottom: 1rem;
  align-items: end;
  min-height: 82vh;
  /*white-space: nowrap;*/
}

.marquee:hover {
  -webkit-text-stroke: 3px var(--accent);
  -webkit-text-fill-color: transparent;
  color: transparent;
  transition: all 0.5s ease;
  -webkit-font-smoothing: antialiased;
}

.marqueeContent {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 100%;
  gap: var(--gap);
  border: none;
  animation: scroll 60s linear infinite;
}

.marqueeContent span {
  border: none;
  color: var(--accent);
  font-size: 10vw;
  font-weight: 800;
  text-transform: uppercase;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - var(--gap)));
  }
}

@media (max-width: 800px) {
  .marquee {
    min-height: 75vh;
  }
}
