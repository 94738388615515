.footer-content {
  border-right: 3px solid var(--color);
  border-left: 3px solid var(--color);
}

.footer-content__start {
  border-top: 2px solid var(--color);
  border-right: none;
  text-align: start;
}

.clickMode {
  background: none;
  border: none;
  margin-left: 15px;
  cursor: pointer;
  color: var(--color);
  /*display: none;*/
}

.footer-content__social {
  border-top: 2px solid var(--color);
  border-left: none;
}

#instagram,
#github {
  padding: 5px;
}

#codesandbox {
  padding: 2px;
}

#instagram,
#github,
#linkedin,
#codepen,
#codesandbox {
  color: var(--color);
  font-size: 40px;
}

.social-box {
  border: none;
  display: flex;
  justify-content: end;
  border-right: 5px solid var(--color);
}

.soc {
  border: none;
}

.footer-content__end {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 2px solid var(--color);
}

.copyright {
  font-size: 12px;
  font-weight: 700;
  margin: 0;
  letter-spacing: 0.05rem;
}

/*Responsive xl*/
@media (max-width: 1400px) {
  .copyright {
    font-size: 12px;
  }
}

/*Responsive sm*/
@media (max-width: 1199px) {
  #themeBtnFooter {
    display: none;
  }

  .copyright {
    padding: 8px;
    font-size: 9px;
  }

  .social-box {
    border: none;
  }

  .footer-content__start {
    display: none;
  }

  #instagram,
  #github,
  #linkedin,
  #codepen,
  #codesandbox {
    font-size: 30px;
  }

  .social-box {
    justify-content: space-around;
  }
}

/*Responsive sm*/
@media (max-width: 767px) {
  .footer-content__social {
    border-bottom: 3px solid var(--color);
    border-top: 1px solid var(--color);
    border-left: 1px solid var(--color);
  }
}
