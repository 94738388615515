* {
  box-sizing: border-box;
}

.ruta {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 3px solid var(--color);
}

.logo {
  width: 350px;
  padding: 10px;
}

.showCurrentNavMenu {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 0;
}

#projecstCol,
#portfolioCol {
  border-right: 5px solid;
}

.navMenuLink {
  text-decoration: none;
  color: var(--color);
}

.navMenu {
  font-size: 25px;
  font-weight: 700;
  margin: 5px 0px 5px 0px;
}

.navMenuDiscription {
  font-size: 16px;
  font-weight: 400;
}

em {
  font-style: normal;
}

.navCol:hover {
  background-color: var(--accent);
  transition: all 0.5s ease;
}

.primaryNav {
  display: flex;
  padding: 0;
}

.mobileNavMenu {
  border: none;
  font-size: 20px;
  display: none;
}

#themeBtnHeader {
  display: none;
}

/*Responsive xl*/
@media (max-width: 1400px) {
  .logo {
    width: 300px;
  }
}

/*Responsive lg*/
@media (max-width: 1200px) {
  .logo {
    width: 200px;
  }

  .showCurrentNavMenu {
    font-size: 46px;
  }

  .navMenu {
    font-size: 20px;
  }

  .navMenuDiscription {
    font-size: 14px;
  }
}

/*Responsive md*/
@media (max-width: 1199px) {
  .logo {
    width: 85px;
  }
  .navMenuRuta {
    display: none;
  }

  .mobileNavMenu {
    display: flex;
  }

  .ruta {
    justify-content: space-between;
  }

  #themeBtnHeader {
    color: var(--accent);
    display: flex;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
    color: var(--accent);
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
  }

  /*  Top Navigation  */
  .menu-btn {
    text-transform: lowercase;
  }

  /*  Dropdown Menu   */
  .dropdown {
    background-color: var(--background-color);
    border-bottom: none;
    border-top: none;
    border-left: 5px solid var(--color);
    border-right: 5px solid var(--color);
    height: 93vh;
  }

  .menu {
    height: 100%;
    width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .drop {
    border: none;
    width: 100%;
    left: 0;
    position: fixed;
    top: 64px;
    z-index: 1;
  }
  .drop.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: all 0.5s ease;
  }

  .drop.inactive {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-30px);
    transition: all 0.5s ease;
  }
}
