:root {
  --background-color-dark: #1d1d1f;
  --background-color-light: #f5f5f7;

  --color-dark: #f5f5f7;
  --color-light: #1d1d1f;

  --accent-dark: #70c488;
  --accent-light: #70c488;

  --error-dark: #b70000;
  --error-light: #e70000;
}

.light-theme {
  --background-color: var(--background-color-light);
  --color: var(--color-light);
  --accent: var(--accent-light);
  --error: var(--error-light);
}

.dark-theme {
  --background-color: var(--background-color-dark);
  --color: var(--color-dark);
  --accent: var(--accent-dark);
  --error: var(--error-dark);
}

.App {
  text-align: center;
  font-family: "briller", sans-serif;
  transition: all 0.2s ease-in-out;
  background: var(--background-color);
  color: var(--color);
}

div {
  border: 1px solid var(--color);
}
