.contactContainer {
  margin: 0;
}

.contactTextarea {
  max-height: 1368px;
  height: 100%;
}

.contactCol {
  padding: 0;
  border-top: none;
  border-left: 3px solid var(--color);
  border-right: 3px solid var(--color);
}

.contactForm {
  border-top: none;
}

.contactDivInput {
  border: none;
}
.contactForm input,
.contactForm textarea {
  border-left: none;
  border-right: none;
  background: var(--background-color);
  color: var(--color);
  width: 100%;
  border-top: 2px solid var(--color);
  border-bottom: 2px solid var(--color);
  padding: 20px 10px;
  font-size: 18px;
  font-weight: 600;
}

#contactFullname {
  border-top: none;
}

#contactMessage {
  border-bottom: none;
}

.contactForm input::placeholder,
.contactForm textarea::placeholder {
  color: var(--color);
  background: var(--background-color);
  border-radius: none;
}

.contactSendBtnDiv {
  font-size: 18px;
  border-bottom: none;
  border-top: 4px solid var(--color);
  border-left: none;
  border-right: none;
}

.contactSendBtn {
  background: none;
  border: none;
  font-weight: 800;
  color: var(--accent);
  padding: 15px 0;
  width: 100%;
  text-transform: uppercase;
}

/* === TOASTIFY === */
.Toastify,
.Toastify__toast-container,
.Toastify__toast,
.Toastify__toast-body,
.Toastify__toast-body div,
.Toastify__toast-icon,
.Toastify__progress-bar {
  border: none !important;
}

.Toastify__toast--success {
  background-color: var(--accent) !important;
  color: var(--color) !important;
}

.Toastify__close-button {
  color: var(--color) !important;
}

/*______Responsive md______*/
@media (max-width: 767px) {
  .contactForm input,
  .contactForm textarea {
    width: 100%;
    padding: 12px 5px;
    font-size: 10px;
  }

  .contactSendBtnDiv {
    font-size: 12px;
  }
}
